var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"tab-box",class:{ top068: !_vm.isShowNews }},[_c('div',{staticClass:"create_tabs"},[_c('ul',{staticClass:"tab_left"},[_c('li',{staticClass:"tab_single",class:_vm.tabKey === '0' ? 'tab_active' : '',on:{"click":function () { return _vm.changeTabs('0'); }}},[_c('span',{staticClass:"user_icon",class:_vm.tabKey === '0' ? 'user_icon_active' : ''}),_vm._v(" "+_vm._s(_vm.$t("blzx.fazxb"))+" ")]),_c('li',{staticClass:"tab_single",class:_vm.tabKey === '1' ? 'tab_active' : '',on:{"click":function () {
              _vm.changeTabs('1');
            }}},[_c('span',{staticClass:"preview_icon",class:_vm.tabKey === '1' ? 'preview_icon_active' : ''}),_vm._v(" "+_vm._s(_vm.$t("blzx.yltj"))+" ")])]),_c('div',{staticClass:"tab_right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabKey !== '0'),expression:"tabKey !== '0'"}],staticClass:"next_page flex-x-y-c curp mr10",class:_vm.tabKey !== '0' ? 'to_active' : '',style:(_vm.lang === 'en_US' ? 'width: 1.2rem' : ''),on:{"click":function () {
              _vm.toStep('0');
            }}},[_vm._v(" "+_vm._s(_vm.$t("cases.casesCreate.syy"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabKey !== '1'),expression:"tabKey !== '1'"}],staticClass:"next_page flex-x-y-c curp mr10",class:_vm.tabKey !== '1' ? 'to_active' : '',style:(_vm.lang === 'en_US' ? 'width: 1.2rem' : ''),on:{"click":function () {
              _vm.toStep('1');
            }}},[_vm._v(" "+_vm._s(_vm.$t("cases.casesCreate.xyy"))+" ")]),_c('div',{staticClass:"save_btn flex-x-y-c curp",class:_vm.tabKey === '1' ? 'to_active' : '',on:{"click":_vm.saveMsg}},[_vm._v(" "+_vm._s(_vm.tabKey !== "1" ? _vm.$t("cases.casesCreate.zc") : _vm.$t("common.message.tj"))+" ")])])])]),(_vm.tabKey === '0')?_c('div',{staticClass:"create_user"},[_c('ul',{staticClass:"form_ul"},[_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"f_single_left",style:(_vm.lang === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : '')},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v(" "+_vm._s(_vm.$t("cases.createUser.hzmc"))+"： ")]),_c('div',{staticClass:"f_single_right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.planDetail.userName),expression:"planDetail.userName"}],attrs:{"placeholder":_vm.$t('cases.createUser.qsr'),"type":"text"},domProps:{"value":(_vm.planDetail.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.planDetail, "userName", $event.target.value)}}})])]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"f_single_left",style:(_vm.lang === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : '')},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v(" "+_vm._s(_vm.$t("cases.createUser.xb"))+"： ")]),_c('div',{staticClass:"f_single_right",staticStyle:{"display":"flex","align-items":"center"}},[_c('radio',{attrs:{"label":"1"},model:{value:(_vm.planDetail.sex),callback:function ($$v) {_vm.$set(_vm.planDetail, "sex", $$v)},expression:"planDetail.sex"}},[_vm._v(_vm._s(_vm.$t("cases.createUser.n"))+" ")]),_c('radio',{attrs:{"label":"2"},model:{value:(_vm.planDetail.sex),callback:function ($$v) {_vm.$set(_vm.planDetail, "sex", $$v)},expression:"planDetail.sex"}},[_vm._v(_vm._s(_vm.$t("cases.createUser.v"))+" ")])],1)]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"f_single_left",style:(_vm.lang === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : '')},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v(" "+_vm._s(_vm.$t("cases.createUser.csrq"))+"： ")]),_c('div',{staticClass:"f_single_right flex-y-c"},[_c('DatePicker',{staticStyle:{"width":"2.74rem"},attrs:{"placeholder":_vm.$t('cases.createUser.xzrq'),"type":"date","value-format":"yyyy-MM-dd"},model:{value:(_vm.planDetail.birthday),callback:function ($$v) {_vm.$set(_vm.planDetail, "birthday", $$v)},expression:"planDetail.birthday"}})],1)]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"f_single_left",style:(_vm.lang === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : '')},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v("1."+_vm._s(_vm.$t("blzx.zs"))+"： ")]),_c('div',{staticClass:"f_single_right flex-y-c"},[_c('el-input',{staticClass:"tex_b_a",attrs:{"autosize":{ minRows: 4, maxRows: 6 },"placeholder":_vm.$t('common.common.qsrnr'),"maxlength":"2000","show-word-limit":"","type":"textarea"},model:{value:(_vm.planDetail.chiefComplaint),callback:function ($$v) {_vm.$set(_vm.planDetail, "chiefComplaint", $$v)},expression:"planDetail.chiefComplaint"}})],1)]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"f_single_left",style:(_vm.lang === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : '')},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v("2."+_vm._s(_vm.$t("blzx.by"))+"： ")]),_c('div',{staticClass:"f_single_right flex-y-c"},[_c('div',{staticClass:"btn-box",class:_vm.toothExtraction === 'true' ? 'active-btn-box' : '',on:{"click":function($event){return _vm.changeExtract('true')}}},[_vm._v(" "+_vm._s(_vm.$t("blzx.Y"))+" ")]),_c('div',{staticClass:"btn-box",class:_vm.toothExtraction === 'false' ? 'active-btn-box' : '',on:{"click":function($event){return _vm.changeExtract('false')}}},[_vm._v(" "+_vm._s(_vm.$t("blzx.N"))+" ")])])]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"f_single_left",style:(_vm.lang === 'en_US'
              ? 'width: 4.3rem; text-align: left;margin-right:0'
              : '')},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v("3. "+_vm._s(_vm.$t("blzx.zkd"))+"： ")]),_c('div',{staticClass:"f_single_right flex-y-c"},[_c('div',{staticClass:"btn-box",class:_vm.growNailFlag === 'true' ? 'active-btn-box' : '',on:{"click":function($event){return _vm.changePlantNail('true')}}},[_vm._v(" "+_vm._s(_vm.$t("blzx.Y"))+" ")]),_c('div',{staticClass:"btn-box",class:_vm.growNailFlag === 'false' ? 'active-btn-box' : '',on:{"click":function($event){return _vm.changePlantNail('false')}}},[_vm._v(" "+_vm._s(_vm.$t("blzx.N"))+" ")])])]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"f_single_left",style:(_vm.lang === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : '')},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v("4."+_vm._s(_vm.$t("blzx.zyzxwt"))+"： ")]),_c('div',{staticClass:"f_single_right flex-y-c"},[_c('el-input',{staticClass:"tex_b_a",attrs:{"autosize":{ minRows: 4, maxRows: 6 },"placeholder":_vm.$t('common.common.qsrnr'),"maxlength":"2000","show-word-limit":"","type":"textarea"},model:{value:(_vm.planDetail.keyAdvisoryQuestions),callback:function ($$v) {_vm.$set(_vm.planDetail, "keyAdvisoryQuestions", $$v)},expression:"planDetail.keyAdvisoryQuestions"}})],1)]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"case-question"},[_c('div',{staticClass:"case-title "},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v(" 5."+_vm._s(_vm.$t("blzx.mxpic"))+" "),_c('div',{staticClass:"link"},[_c('img',{staticClass:"dib mr4",staticStyle:{"width":"12px"},attrs:{"alt":"","src":require("@/common/imgs/case/creater_img_ask.png")}}),_c('router-link',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"to":{ name: 'helpCenter', query: { type: '12' } },"target":"_blank"}},[_c('span',{staticClass:"main_theme_color fz14 curp"},[_vm._v(_vm._s(_vm.$t("cases.createImage.zppsbz")))])])],1)]),_c('div',{staticClass:"c-q-content"},[_c('div',{staticClass:"upload-tips"},[_vm._v(_vm._s(_vm.$t("cases.maintain.ktztp")))]),_c('div',{staticClass:"upload-img-container"},_vm._l((_vm.facePic),function(item,index){return _c('div',{key:index,staticClass:"upload-img-item"},[(_vm.resultObj[item.id] && _vm.resultObj[item.id].nanoId)?_c('div',{staticClass:"after-upload",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return (function (event) {
                      _vm.handleDrop(
                        event,
                        item.id,
                        _vm.$t(("cases.createImage." + (item.label)))
                      );
                    })($event)},"click":function($event){_vm.showCarousel({
                      filed: item.id,
                      text: item.name,
                      index: _vm.getPicIndex(item.id)
                    })}}},[(_vm.resultObj[item.id] && _vm.resultObj[item.id].nanoId)?_c('img',{staticClass:"after-upload-img",style:({
                      transform: ("rotate(" + (_vm.resultObj[item.id]['degree']) + "deg) scaleY(" + (_vm.resultObj[item.id]['vertical'] ? -1 : 1) + ") scaleX(" + (_vm.resultObj[item.id]['horizontal'] ? -1 : 1) + ")")
                    }),attrs:{"src":("" + _vm.$PicPrefix + (_vm.resultObj[item.id].nanoId) + "?imageView2/0/w/360/h/250"),"alt":""}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.resultObj[item.id] && _vm.resultObj[item.id].nanoId),expression:"resultObj[item.id] && resultObj[item.id].nanoId"}],staticClass:"remove_pic",on:{"click":function () { return _vm.removeFiledPic(item.id); }}}),_c('p',{staticClass:"pic_look"},[_c('img',{attrs:{"alt":"","src":"/static/images/createCases/check_icon.png"}}),_c('span',[_vm._v(_vm._s(_vm.$t("cases.createImage.ck")))])])]):_c('div',{staticClass:"before-upload"},[_c('el-upload',{staticClass:"upload-content",attrs:{"action":_vm.UPLOAD_URL,"before-upload":_vm.beforeUpload,"data":{ token: _vm.uploadToken, key: _vm.nanoid },"on-error":_vm.uploadError,"on-success":function (res) { return _vm.uploadSuccess(
                          res,
                          item.id,
                          _vm.$t(("cases.createImage." + (item.label)))
                        ); },"show-file-list":false,"accept":"image/jpeg,image/bmp,image/png","drag":"","list-type":"picture"}},[_c('div',{staticClass:"up_box"},[_c('p',{staticClass:"up_box_tip"},[_c('span',{staticClass:"up___tip_icon"}),_vm._v(_vm._s(_vm.$t("cases.createImage.sctz"))+" ")])]),_c('p',{class:("up_load_def up_load_def" + (index + 1))})])],1),_c('div',{staticClass:"img-name"},[_c('span',{staticClass:"main_theme_color_red mr4"},[_vm._v("●")]),_vm._v(_vm._s(item.name)+" ")])])}),0)])])]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"case-question"},[_c('div',{staticClass:"case-title"},[_c('span',{staticClass:"d_require"},[_vm._v("●")]),_vm._v(" 6."+_vm._s(_vm.$t("blzx.xpic"))+" "),_c('div',{staticClass:"link"},[_c('img',{staticClass:"dib mr4",staticStyle:{"width":"12px"},attrs:{"alt":"","src":require("@/common/imgs/case/creater_img_ask.png")}}),_c('router-link',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"to":{ name: 'helpCenter', query: { type: '13' } },"target":"_blank"}},[_c('span',{staticClass:"fz14 main_theme_color"},[_vm._v(_vm._s(_vm.$t("cases.createImage.xgpbz")))])])],1)]),_c('div',{staticClass:"c-q-content"},[_c('div',{staticClass:"upload-tips"},[_vm._v(_vm._s(_vm.$t("cases.maintain.ktztp")))]),_c('div',{staticClass:"upload-img-container"},_vm._l((_vm.XRayPic),function(item,index){return _c('div',{key:index,staticClass:"upload-img-item"},[(_vm.resultObj[item.id] && _vm.resultObj[item.id].nanoId)?_c('div',{staticClass:"after-upload",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return (function (event) {
                      _vm.handleDrop(
                        event,
                        item.id,
                        _vm.$t(("cases.createImage." + (item.label)))
                      );
                    })($event)},"click":function($event){_vm.showCarousel({
                      filed: item.id,
                      text: item.name,
                      index: _vm.getPicIndex(item.id)
                    })}}},[(_vm.resultObj[item.id] && _vm.resultObj[item.id].nanoId)?_c('img',{staticClass:"after-upload-img",style:({
                      transform: ("rotate(" + (_vm.resultObj[item.id]['degree']) + "deg) scaleY(" + (_vm.resultObj[item.id]['vertical'] ? -1 : 1) + ") scaleX(" + (_vm.resultObj[item.id]['horizontal'] ? -1 : 1) + ")")
                    }),attrs:{"src":("" + _vm.$PicPrefix + (_vm.resultObj[item.id].nanoId) + "?imageView2/0/w/360/h/250"),"alt":""}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.resultObj[item.id] && _vm.resultObj[item.id].nanoId),expression:"resultObj[item.id] && resultObj[item.id].nanoId"}],staticClass:"remove_pic",on:{"click":function () { return _vm.removeFiledPic(item.id); }}}),_c('p',{staticClass:"pic_look"},[_c('img',{attrs:{"alt":"","src":"/static/images/createCases/check_icon.png"}}),_c('span',[_vm._v(_vm._s(_vm.$t("cases.createImage.ck")))])])]):_c('div',{staticClass:"before-upload"},[_c('el-upload',{staticClass:"upload-content",attrs:{"action":_vm.UPLOAD_URL,"before-upload":_vm.beforeUpload,"data":{ token: _vm.uploadToken, key: _vm.nanoid },"on-error":_vm.uploadError,"on-success":function (res) { return _vm.uploadSuccess(
                          res,
                          item.id,
                          _vm.$t(("cases.createImage." + (item.label)))
                        ); },"show-file-list":false,"accept":"image/jpeg,image/bmp,image/png","drag":"","list-type":"picture"}},[_c('div',{staticClass:"up_box"},[_c('p',{staticClass:"up_box_tip"},[_c('span',{staticClass:"up___tip_icon"}),_vm._v(_vm._s(_vm.$t("cases.createImage.sctz"))+" ")])]),_c('p',{staticClass:"up_load_def up_load_def10"})])],1),_c('div',{staticClass:"img-name"},[_c('span',{staticClass:"main_theme_color_red mr4"},[_vm._v("●")]),_vm._v(_vm._s(item.name)+" ")])])}),0)])])]),_c('li',{staticClass:"f_single"},[_c('div',{staticClass:"case-question",attrs:{"id":"dentalModel"}},[_c('div',{staticClass:"case-title "},[_vm._v("7."+_vm._s(_vm.$t("cases.maintain.yhmx")))]),_c('div',{staticClass:"c-q-content"},[_c('div',[_c('div',{staticClass:"button-filed-item"},[_c('div',{staticClass:"label",style:({
                    width: _vm.lang === 'zh_CN' ? '1.0rem' : '1.4rem'
                  })},[_vm._v(" "+_vm._s(_vm.$t("cases.createImage.szmxwz"))+" ")]),_c('div',{staticClass:"filed-value"},[_c('CommonButton',{attrs:{"echoContent":_vm.resultObj['stlFile'],"renderList":_vm.digitalModelFile,"parentKey":"stlFile"},on:{"buttonChange":_vm.buttonHandlerChange}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resultObj['stlFile'] === 'local_upload'),expression:"resultObj['stlFile'] === 'local_upload'"}],style:({
                  marginLeft: _vm.lang === 'zh_CN' ? '1.2rem' : '1.6rem'
                })},[_c('div',{staticClass:"s_c__up"},[_vm._v(" "+_vm._s(_vm.$t("cases.createImage.se"))+" "),_c('el-upload',{ref:"sharpUpJaw",staticClass:"upload-file",attrs:{"show-file-list":false,"action":_vm.UPLOAD_URL,"before-upload":function (file) { return _vm.beforeFileUpload(file, 'sharp_upjaw', 'zip', 'other'); },"data":{ token: _vm.uploadToken },"drag":""}},[_c('span',{staticClass:"up_load main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createImage.scstl")))])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileNameObj['sharp_upjaw']),expression:"fileNameObj['sharp_upjaw']"}],staticClass:"up_file"},[_vm._v(" "+_vm._s(_vm.fileNameObj["sharp_upjaw"])+" "),_c('span',{staticClass:"up_status el-icon-success"})])],1),_c('div',{staticClass:"s_c__up"},[_vm._v(" "+_vm._s(_vm.$t("cases.createImage.xe"))+" "),_c('el-upload',{ref:"sharpDownJaw",staticClass:"upload-file",attrs:{"show-file-list":false,"action":_vm.UPLOAD_URL,"before-upload":function (file) { return _vm.beforeFileUpload(
                          file,
                          'sharp_downjaw',
                          'zip',
                          'other'
                        ); },"data":{ token: _vm.uploadToken },"drag":""}},[_c('span',{staticClass:"up_load main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createImage.scstl")))])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileNameObj['sharp_downjaw']),expression:"fileNameObj['sharp_downjaw']"}],staticClass:"up_file"},[_vm._v(" "+_vm._s(_vm.fileNameObj["sharp_downjaw"])+" "),_c('span',{staticClass:"up_status el-icon-success"})])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resultObj['stlFile'] === 'iTero'),expression:"resultObj['stlFile'] === 'iTero'"}],style:({
                  marginLeft: _vm.lang === 'zh_CN' ? '1.2rem' : '1.6rem'
                })},[_c('div',{staticClass:"s_c__up"},[_vm._v(" "+_vm._s(_vm.$t("cases.createImage.wj"))+" "),_c('el-upload',{ref:"iTero",staticClass:"upload-file",attrs:{"show-file-list":false,"action":_vm.UPLOAD_URL,"before-upload":function (file) { return _vm.beforeFileUpload(file, 'iTero', 'zip', 'other'); },"data":{ token: _vm.uploadToken },"drag":""}},[_c('span',{staticClass:"up_load main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createImage.sczip")))])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileNameObj['iTero']),expression:"fileNameObj['iTero']"}],staticClass:"up_file"},[_vm._v(" "+_vm._s(_vm.fileNameObj["iTero"])+" "),_c('span',{staticClass:"up_status el-icon-success"})])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resultObj['stlFile'] === 'other'),expression:"resultObj['stlFile'] === 'other'"}],style:({
                  marginLeft: _vm.lang === 'zh_CN' ? '1.2rem' : '1.6rem'
                })},[_c('div',{staticClass:"s_c__up"},[_vm._v(" "+_vm._s(_vm.$t("cases.createImage.wj"))+" "),_c('el-upload',{ref:"otherFile",staticClass:"upload-file",attrs:{"action":_vm.UPLOAD_URL,"before-upload":function (file) { return _vm.beforeFileUpload(file, 'other_file', 'zip', 'other'); },"data":{ token: _vm.uploadToken },"file-list":_vm.fileNameObj['other_file'],"limit":10,"on-exceed":_vm.otherListInit,"show-file-list":false,"drag":"","multiple":""}},[_c('span',{staticClass:"up_load main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createImage.sczip")))])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.stlFile === 'other' &&
                        _vm.fileNameObj['other_file'] &&
                        _vm.fileNameObj['other_file'].length
                    ),expression:"\n                      stlFile === 'other' &&\n                        fileNameObj['other_file'] &&\n                        fileNameObj['other_file'].length\n                    "}]},_vm._l((_vm.fileNameObj['other_file']),function(item,index){return _c('li',{key:index,staticClass:"up_file other_file"},[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"up_status el-icon-success"}),_c('span',{staticClass:"up_status el-icon-close curp",on:{"click":function($event){return _vm.deleteOtherFile(index, item.id)}}})])}),0)],1)])])])])])])]):_vm._e(),_c('el-dialog',{attrs:{"width":"500px","center":"","show-close":false}},[_c('div',[_c('img',{staticStyle:{"display":"block","margin":"auto auto 10px"},attrs:{"src":require("../../common/imgs/consultation/tip.png"),"alt":""}})]),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" 是否提交？ ")]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"width":"2rem"},on:{"click":function($event){_vm.dialogTipVisible = false}}},[_vm._v("取 消 ")]),_c('el-button',{staticStyle:{"width":"2rem","color":"#333"},attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定 ")])],1)]),(_vm.tabKey === '1')?_c('div',{staticClass:"create_user"},[_c('PlanPreview',{attrs:{"id":_vm.schemeId},on:{"jumpQuestion":_vm.jumpQuestion}})],1):_vm._e(),_c('confirm-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogTipVisible),expression:"dialogTipVisible"}],attrs:{"confirmObj":{
      tipText: _vm.$t('cases.casesCreate.sftj'),
      backText: _vm.$t('casesDetail.casesChildren.common.qx'),
      okText: _vm.$t('cases.casesCreate.qdtj')
    }},on:{"confirmSubmit":function($event){return _vm.submit()},"confirmHide":function($event){_vm.dialogTipVisible = false}}}),_c('PreviewImg',{ref:"previewImg",attrs:{"carouselList":_vm.carouselList},on:{"imgChange":_vm.imgChange}}),(_vm.isLoading)?_c('FullLoading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }