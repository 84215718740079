<template>
  <div class="container">
    <div class="tab-box" :class="{ top068: !isShowNews }">
      <div class="create_tabs">
        <ul class="tab_left">
          <li
            :class="tabKey === '0' ? 'tab_active' : ''"
            class="tab_single"
            @click="() => changeTabs('0')"
          >
            <span
              class="user_icon"
              :class="tabKey === '0' ? 'user_icon_active' : ''"
            ></span>
            {{ $t("blzx.fazxb") }}
          </li>
          <li
            :class="tabKey === '1' ? 'tab_active' : ''"
            class="tab_single"
            @click="
              () => {
                changeTabs('1');
              }
            "
          >
            <span
              class="preview_icon"
              :class="tabKey === '1' ? 'preview_icon_active' : ''"
            ></span>
            {{ $t("blzx.yltj") }}
          </li>
        </ul>
        <div class="tab_right">
          <div
            v-show="tabKey !== '0'"
            :class="tabKey !== '0' ? 'to_active' : ''"
            :style="lang === 'en_US' ? 'width: 1.2rem' : ''"
            class="next_page flex-x-y-c curp mr10"
            @click="
              () => {
                toStep('0');
              }
            "
          >
            {{ $t("cases.casesCreate.syy") }}
          </div>
          <div
            v-show="tabKey !== '1'"
            :class="tabKey !== '1' ? 'to_active' : ''"
            :style="lang === 'en_US' ? 'width: 1.2rem' : ''"
            class="next_page flex-x-y-c curp mr10"
            @click="
              () => {
                toStep('1');
              }
            "
          >
            {{ $t("cases.casesCreate.xyy") }}
          </div>
          <div
            :class="tabKey === '1' ? 'to_active' : ''"
            class="save_btn flex-x-y-c curp"
            @click="saveMsg"
          >
            {{
              tabKey !== "1"
                ? $t("cases.casesCreate.zc")
                : $t("common.message.tj")
            }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="tabKey === '0'" class="create_user">
      <ul class="form_ul">
        <li class="f_single">
          <div
            :style="
              lang === 'en_US'
                ? 'width: 2.3rem; text-align: left;margin-right:0'
                : ''
            "
            class="f_single_left"
          >
            <span class="d_require">●</span>
            {{ $t("cases.createUser.hzmc") }}：
          </div>
          <div class="f_single_right">
            <input
              v-model="planDetail.userName"
              :placeholder="$t('cases.createUser.qsr')"
              type="text"
            />
          </div>
        </li>
        <li class="f_single">
          <div
            :style="
              lang === 'en_US'
                ? 'width: 2.3rem; text-align: left;margin-right:0'
                : ''
            "
            class="f_single_left"
          >
            <span class="d_require">●</span> {{ $t("cases.createUser.xb") }}：
          </div>
          <div
            class="f_single_right"
            style="display: flex; align-items: center"
          >
            <radio v-model="planDetail.sex" label="1"
              >{{ $t("cases.createUser.n") }}
            </radio>
            <radio v-model="planDetail.sex" label="2"
              >{{ $t("cases.createUser.v") }}
            </radio>
          </div>
        </li>
        <li class="f_single">
          <div
            :style="
              lang === 'en_US'
                ? 'width: 2.3rem; text-align: left;margin-right:0'
                : ''
            "
            class="f_single_left"
          >
            <span class="d_require">●</span>
            {{ $t("cases.createUser.csrq") }}：
          </div>
          <div class="f_single_right flex-y-c">
            <DatePicker
              v-model="planDetail.birthday"
              :placeholder="$t('cases.createUser.xzrq')"
              style="width: 2.74rem"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </DatePicker>
            <!-- <div v-if="!userForm.birthday" class="ml20 fz12 main_theme_color_red">
              {{ birthdayHintText }}
            </div> -->
          </div>
        </li>
        <li class="f_single">
          <div
            :style="
              lang === 'en_US'
                ? 'width: 2.3rem; text-align: left;margin-right:0'
                : ''
            "
            class="f_single_left"
          >
            <span class="d_require">●</span>1.{{ $t("blzx.zs") }}：
          </div>
          <div class="f_single_right flex-y-c">
            <el-input
              v-model="planDetail.chiefComplaint"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('common.common.qsrnr')"
              class="tex_b_a"
              maxlength="2000"
              show-word-limit
              type="textarea"
            />
          </div>
        </li>
        <li class="f_single">
          <div
            :style="
              lang === 'en_US'
                ? 'width: 2.3rem; text-align: left;margin-right:0'
                : ''
            "
            class="f_single_left"
          >
            <span class="d_require">●</span>2.{{ $t("blzx.by") }}：
          </div>
          <div class="f_single_right flex-y-c">
            <div
              class="btn-box"
              :class="toothExtraction === 'true' ? 'active-btn-box' : ''"
              @click="changeExtract('true')"
            >
              {{ $t("blzx.Y") }}
            </div>
            <div
              class="btn-box"
              :class="toothExtraction === 'false' ? 'active-btn-box' : ''"
              @click="changeExtract('false')"
            >
              {{ $t("blzx.N") }}
            </div>
          </div>
        </li>
        <li class="f_single">
          <div
            :style="
              lang === 'en_US'
                ? 'width: 4.3rem; text-align: left;margin-right:0'
                : ''
            "
            class="f_single_left"
          >
            <span class="d_require">●</span>3. {{ $t("blzx.zkd") }}：
          </div>
          <div class="f_single_right flex-y-c">
            <div
              class="btn-box"
              :class="growNailFlag === 'true' ? 'active-btn-box' : ''"
              @click="changePlantNail('true')"
            >
              {{ $t("blzx.Y") }}
            </div>
            <div
              class="btn-box"
              :class="growNailFlag === 'false' ? 'active-btn-box' : ''"
              @click="changePlantNail('false')"
            >
              {{ $t("blzx.N") }}
            </div>
          </div>
        </li>
        <li class="f_single">
          <div
            :style="
              lang === 'en_US'
                ? 'width: 2.3rem; text-align: left;margin-right:0'
                : ''
            "
            class="f_single_left"
          >
            <span class="d_require">●</span>4.{{ $t("blzx.zyzxwt") }}：
          </div>
          <div class="f_single_right flex-y-c">
            <el-input
              v-model="planDetail.keyAdvisoryQuestions"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('common.common.qsrnr')"
              class="tex_b_a"
              maxlength="2000"
              show-word-limit
              type="textarea"
            />
          </div>
        </li>
        <li class="f_single">
          <div class="case-question">
            <div class="case-title ">
              <span class="d_require">●</span> 5.{{ $t("blzx.mxpic") }}
              <div class="link">
                <img
                  alt=""
                  class="dib mr4"
                  src="@/common/imgs/case/creater_img_ask.png"
                  style="width: 12px"
                />
                <router-link
                  :to="{ name: 'helpCenter', query: { type: '12' } }"
                  style="display: flex; align-items: center"
                  target="_blank"
                >
                  <span class="main_theme_color fz14 curp">{{
                    $t("cases.createImage.zppsbz")
                  }}</span>
                </router-link>
              </div>
            </div>
            <div class="c-q-content">
              <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
              <div class="upload-img-container">
                <div
                  v-for="(item, index) in facePic"
                  :key="index"
                  class="upload-img-item"
                >
                  <div
                    v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                    class="after-upload"
                    @dragover.prevent
                    @drop.prevent="
                      event => {
                        handleDrop(
                          event,
                          item.id,
                          $t(`cases.createImage.${item.label}`)
                        );
                      }
                    "
                    @click="
                      showCarousel({
                        filed: item.id,
                        text: item.name,
                        index: getPicIndex(item.id)
                      })
                    "
                  >
                    <img
                      v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                      :src="
                        `${$PicPrefix}${
                          resultObj[item.id].nanoId
                        }?imageView2/0/w/360/h/250`
                      "
                      :style="{
                        transform: `rotate(${
                          resultObj[item.id]['degree']
                        }deg) scaleY(${
                          resultObj[item.id]['vertical'] ? -1 : 1
                        }) scaleX(${resultObj[item.id]['horizontal'] ? -1 : 1})`
                      }"
                      alt=""
                      class="after-upload-img"
                    />
                    <span
                      v-show="resultObj[item.id] && resultObj[item.id].nanoId"
                      class="remove_pic"
                      @click="() => removeFiledPic(item.id)"
                    ></span>
                    <p class="pic_look">
                      <img
                        alt=""
                        src="/static/images/createCases/check_icon.png"
                      />
                      <span>{{ $t("cases.createImage.ck") }}</span>
                    </p>
                  </div>
                  <div v-else class="before-upload">
                    <el-upload
                      :action="UPLOAD_URL"
                      :before-upload="beforeUpload"
                      :data="{ token: uploadToken, key: nanoid }"
                      :on-error="uploadError"
                      :on-success="
                        res =>
                          uploadSuccess(
                            res,
                            item.id,
                            $t(`cases.createImage.${item.label}`)
                          )
                      "
                      :show-file-list="false"
                      accept="image/jpeg,image/bmp,image/png"
                      class="upload-content"
                      drag
                      list-type="picture"
                    >
                      <div class="up_box">
                        <p class="up_box_tip">
                          <span class="up___tip_icon"></span
                          >{{ $t("cases.createImage.sctz") }}
                        </p>
                      </div>
                      <p :class="`up_load_def up_load_def${index + 1}`"></p>
                    </el-upload>
                  </div>
                  <div class="img-name">
                    <span class="main_theme_color_red mr4">●</span
                    >{{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="f_single">
          <div class="case-question">
            <div class="case-title">
              <span class="d_require">●</span> 6.{{ $t("blzx.xpic") }}
              <div class="link">
                <img
                  alt=""
                  class="dib mr4"
                  src="@/common/imgs/case/creater_img_ask.png"
                  style="width: 12px"
                />
                <router-link
                  :to="{ name: 'helpCenter', query: { type: '13' } }"
                  style="display: flex; align-items: center"
                  target="_blank"
                >
                  <span class="fz14 main_theme_color">{{
                    $t("cases.createImage.xgpbz")
                  }}</span>
                </router-link>
              </div>
            </div>
            <div class="c-q-content">
              <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
              <div class="upload-img-container">
                <div
                  v-for="(item, index) in XRayPic"
                  :key="index"
                  class="upload-img-item"
                >
                  <div
                    v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                    class="after-upload"
                    @dragover.prevent
                    @drop.prevent="
                      event => {
                        handleDrop(
                          event,
                          item.id,
                          $t(`cases.createImage.${item.label}`)
                        );
                      }
                    "
                    @click="
                      showCarousel({
                        filed: item.id,
                        text: item.name,
                        index: getPicIndex(item.id)
                      })
                    "
                  >
                    <img
                      v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                      :src="
                        `${$PicPrefix}${
                          resultObj[item.id].nanoId
                        }?imageView2/0/w/360/h/250`
                      "
                      :style="{
                        transform: `rotate(${
                          resultObj[item.id]['degree']
                        }deg) scaleY(${
                          resultObj[item.id]['vertical'] ? -1 : 1
                        }) scaleX(${resultObj[item.id]['horizontal'] ? -1 : 1})`
                      }"
                      alt=""
                      class="after-upload-img"
                    />
                    <span
                      v-show="resultObj[item.id] && resultObj[item.id].nanoId"
                      class="remove_pic"
                      @click="() => removeFiledPic(item.id)"
                    ></span>
                    <p class="pic_look">
                      <img
                        alt=""
                        src="/static/images/createCases/check_icon.png"
                      />
                      <span>{{ $t("cases.createImage.ck") }}</span>
                    </p>
                  </div>
                  <div v-else class="before-upload">
                    <el-upload
                      :action="UPLOAD_URL"
                      :before-upload="beforeUpload"
                      :data="{ token: uploadToken, key: nanoid }"
                      :on-error="uploadError"
                      :on-success="
                        res =>
                          uploadSuccess(
                            res,
                            item.id,
                            $t(`cases.createImage.${item.label}`)
                          )
                      "
                      :show-file-list="false"
                      accept="image/jpeg,image/bmp,image/png"
                      class="upload-content"
                      drag
                      list-type="picture"
                    >
                      <div class="up_box">
                        <p class="up_box_tip">
                          <span class="up___tip_icon"></span
                          >{{ $t("cases.createImage.sctz") }}
                        </p>
                      </div>
                      <p class="up_load_def up_load_def10"></p>
                    </el-upload>
                  </div>
                  <div class="img-name">
                    <span class="main_theme_color_red mr4">●</span
                    >{{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!--         牙颌模型-->
        <li class="f_single">
          <div id="dentalModel" class="case-question">
            <div class="case-title ">7.{{ $t("cases.maintain.yhmx") }}</div>
            <div class="c-q-content">
              <div>
                <div class="button-filed-item">
                  <div
                    :style="{
                      width: lang === 'zh_CN' ? '1.0rem' : '1.4rem'
                    }"
                    class="label"
                  >
                    {{ $t("cases.createImage.szmxwz") }}
                  </div>
                  <div class="filed-value">
                    <CommonButton
                      :echoContent="resultObj['stlFile']"
                      :renderList="digitalModelFile"
                      parentKey="stlFile"
                      @buttonChange="buttonHandlerChange"
                    />
                  </div>
                </div>
                <div
                  v-show="resultObj['stlFile'] === 'local_upload'"
                  :style="{
                    marginLeft: lang === 'zh_CN' ? '1.2rem' : '1.6rem'
                  }"
                >
                  <div class="s_c__up">
                    {{ $t("cases.createImage.se") }}
                    <el-upload
                      :show-file-list="false"
                      ref="sharpUpJaw"
                      :action="UPLOAD_URL"
                      :before-upload="
                        file =>
                          beforeFileUpload(file, 'sharp_upjaw', 'zip', 'other')
                      "
                      :data="{ token: uploadToken }"
                      class="upload-file"
                      drag
                    >
                      <span class="up_load main_theme_color_999 fz14">{{
                        $t("cases.createImage.scstl")
                      }}</span>
                    </el-upload>
                    <!-- <span
                      class="up_icon"
                      @click="clickSharpUpJaw('sharpUpJaw')"
                    ></span> -->
                    <p v-show="fileNameObj['sharp_upjaw']" class="up_file">
                      {{ fileNameObj["sharp_upjaw"] }}
                      <span class="up_status el-icon-success"></span>
                    </p>
                  </div>
                  <div class="s_c__up">
                    {{ $t("cases.createImage.xe") }}
                    <el-upload
                      :show-file-list="false"
                      ref="sharpDownJaw"
                      :action="UPLOAD_URL"
                      :before-upload="
                        file =>
                          beforeFileUpload(
                            file,
                            'sharp_downjaw',
                            'zip',
                            'other'
                          )
                      "

                      :data="{ token: uploadToken }"
                      class="upload-file"
                      drag
                    >
                      <span class="up_load main_theme_color_999 fz14">{{
                        $t("cases.createImage.scstl")
                      }}</span>
                    </el-upload>
                    <!-- <span
                      class="up_icon"
                      @click="clickSharpUpJaw('sharpDownJaw')"
                    ></span> -->
                    <p v-show="fileNameObj['sharp_downjaw']" class="up_file">
                      {{ fileNameObj["sharp_downjaw"] }}
                      <span class="up_status el-icon-success"></span>
                    </p>
                  </div>
                </div>
                <div
                  v-show="resultObj['stlFile'] === 'iTero'"
                  :style="{
                    marginLeft: lang === 'zh_CN' ? '1.2rem' : '1.6rem'
                  }"
                >
                  <div class="s_c__up">
                    {{ $t("cases.createImage.wj") }}
                    <el-upload
                      :show-file-list="false"
                      ref="iTero"
                      :action="UPLOAD_URL"
                      :before-upload="
                        file => beforeFileUpload(file, 'iTero', 'zip', 'other')
                      "
                      :data="{ token: uploadToken }"
                      class="upload-file"
                      drag
                    >
                      <span class="up_load main_theme_color_999 fz14">{{
                        $t("cases.createImage.sczip")
                      }}</span>
                    </el-upload>
                    <!-- <span class="up_icon" @click="clickSharpUpJaw('iTero')"></span> -->
                    <p v-show="fileNameObj['iTero']" class="up_file">
                      {{ fileNameObj["iTero"] }}
                      <span class="up_status el-icon-success"></span>
                    </p>
                  </div>
                </div>
                <div
                  v-show="resultObj['stlFile'] === 'other'"
                  :style="{
                    marginLeft: lang === 'zh_CN' ? '1.2rem' : '1.6rem'
                  }"
                >
                  <div class="s_c__up">
                    {{ $t("cases.createImage.wj") }}
                    <el-upload
                      ref="otherFile"
                      :action="UPLOAD_URL"
                      :before-upload="
                        file =>
                          beforeFileUpload(file, 'other_file', 'zip', 'other')
                      "
                      :data="{ token: uploadToken }"
                      :file-list="fileNameObj['other_file']"
                      :limit="10"
                      :on-exceed="otherListInit"
                      :show-file-list="false"
                      class="upload-file"
                      drag
                      multiple
                    >
                      <span class="up_load main_theme_color_999 fz14">{{
                        $t("cases.createImage.sczip")
                      }}</span>
                    </el-upload>
                    <!-- <span
                      class="up_icon"
                      @click="clickSharpUpJaw('otherFile')"
                    ></span> -->
                    <ul
                      v-show="
                        stlFile === 'other' &&
                          fileNameObj['other_file'] &&
                          fileNameObj['other_file'].length
                      "
                    >
                      <li
                        v-for="(item, index) in fileNameObj['other_file']"
                        :key="index"
                        class="up_file other_file"
                      >
                        {{ item.name }}
                        <span class="up_status el-icon-success"></span>
                        <span
                          class="up_status el-icon-close curp"
                          @click="deleteOtherFile(index, item.id)"
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-dialog width="500px" center :show-close="false">
      <div>
        <img
          style="display: block;margin: auto auto 10px;"
          src="../../common/imgs/consultation/tip.png"
          alt=""
        />
      </div>
      <p style="text-align: center">
        是否提交？
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button style="width: 2rem;" @click="dialogTipVisible = false"
          >取 消
        </el-button>
        <el-button
          style="width: 2rem;color:#333;"
          type="primary"
          @click="submit"
          >确 定
        </el-button>
      </div>
    </el-dialog>
    <div v-if="tabKey === '1'" class="create_user">
      <PlanPreview @jumpQuestion="jumpQuestion" :id="schemeId" />
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('cases.casesCreate.sftj'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('cases.casesCreate.qdtj')
      }"
      @confirmSubmit="submit()"
      @confirmHide="dialogTipVisible = false"
      v-show="dialogTipVisible"
    />
    <PreviewImg
      ref="previewImg"
      :carouselList="carouselList"
      @imgChange="imgChange"
    />

    <FullLoading v-if="isLoading" />
  </div>
</template>
<script>
import { DatePicker, Radio } from "element-ui";
import { extend, filter, map } from "lodash";
import { UPLOAD_URL } from "common/js/config";
import { nanoid } from "nanoid";
import { notifyMsg } from "common/js/util";
import { getQiniuToken } from "common/api/public";
import CommonButton from "@/pages/cases/cases-detail/casesChildren/components/common-button.vue";
import { schemeDetail, schemeSave, schemeSubmit } from "common/api/cases";
import PreviewImg from "components/preview-image/preview-image.vue";
import * as QINIU from "qiniu-js";
import PlanPreview from "@/pages/consultation/plan-preview.vue";
import FullLoading from "components/full-loading/full-loading";
import ConfirmModal from "components/confirm-modal/confirm.vue";
import { mapState } from "vuex";

export default {
  components: {
    ConfirmModal,
    PreviewImg,
    CommonButton,
    Radio,
    DatePicker,
    PlanPreview,
    FullLoading
  },
  data() {
    return {
      isLoading: false,
      dialogTipVisible: false,
      schemeId: "",
      // 提交数据
      stlFile: "",
      UPLOAD_URL,
      uploadToken: "",
      nanoid: nanoid(),
      tabKey: "0",
      isCurePlanSubmit: false,
      lang: localStorage.getItem("user_lang") || "zh_CN",
      toothExtraction: "",
      growNailFlag: "",
      carouselList: [],
      planDetail: {
        userName: undefined,
        sex: undefined,
        birthday: undefined,
        toothExtraction: "",
        growNailFlag: "",
        chiefComplaint: undefined,
        keyAdvisoryQuestions: undefined
      },
      resultObj: {
        retainer: "",
        alignerBasedOn: "",
        materialSelection: "",
        removeAttachment: "",
        aestheticCutting: "",
        reservedPosition: "",
        occlusalGuide: "",
        sideProfile: "",
        frontal: "",
        frontalSmile: "",
        upperArch: "",
        anteriorOverjet: "",
        lowerArch: "",
        intraOralRight: "",
        intraOralFrontal: "",
        intraOralLeft: "",
        panoramicRadiograph: "",
        lateralCephalogram: "",
        dentalModel: "",
        siliconeImpression: "",
        stlFile: "",
        attachmentList: []
      },
      imgList: [
        "sideProfile",
        "frontal",
        "frontalSmile",
        "upperArch",
        "anteriorOverjet",
        "lowerArch",
        "intraOralRight",
        "intraOralFrontal",
        "panoramicRadiograph",
        "lateralCephalogram",
        "intraOralLeft"
      ],
      facePic: [
        {
          id: "sideProfile",
          name: this.$t("cases.createImage.cmx"),
          label: "cmx"
        },
        {
          id: "frontal",
          name: this.$t("cases.createImage.zmx"),
          label: "zmx"
        },
        {
          id: "frontalSmile",
          name: this.$t("cases.createImage.wxx"),
          label: "wxx"
        },
        {
          id: "upperArch",
          name: this.$t("cases.createImage.sylx"),
          label: "sylx"
        },
        {
          id: "anteriorOverjet",
          name: this.$t("cases.createImage.qyfhfgx"),
          label: "qyfhfgx"
        },
        {
          id: "lowerArch",
          name: this.$t("cases.createImage.xylx"),
          label: "xylx"
        },
        {
          id: "intraOralRight",
          name: this.$t("cases.createImage.knzcyx"),
          label: "knzcyx"
        },
        {
          id: "intraOralFrontal",
          name: this.$t("cases.createImage.knzwx"),
          label: "knzwx"
        },
        {
          id: "intraOralLeft",
          name: this.$t("cases.createImage.knycyx"),
          label: "knycyx"
        }
      ],
      XRayPic: [
        {
          id: "panoramicRadiograph",
          name: this.$t("cases.createImage.dcp"),
          label: "dcp"
        },
        {
          id: "lateralCephalogram",
          name: this.$t("cases.createImage.dwp"),
          label: "dwp"
        }
      ],
      uploadDict: {
        other_file: "Other",
        iTero: "iTero",
        sharp_upjaw: "local_upload",
        sharp_downjaw: "local_upload"
      },
      digitalModelFile: [
        {
          key: "local_upload",
          value: this.$t("blzx.upload"),
          enValue: "Upload"
        },
        {
          key: "iTero",
          value: this.$t("blzx.iTero"),
          enValue: "iTero"
        },
        {
          key: "other",
          value: this.$t("blzx.other"),
          enValue: "Others"
        }
      ],
      fileNameObj: {
        other_file: [],
        iTero: "",
        sharp_upjaw: "",
        sharp_downjaw: ""
      },
      idxObj: {
        sideProfile: 0,
        frontal: 1,
        frontalSmile: 2,
        upperArch: 3,
        anteriorOverjet: 4,
        lowerArch: 5,
        intraOralRight: 6,
        intraOralFrontal: 7,
        intraOralLeft: 8,
        panoramicRadiograph: 9,
        lateralCephalogram: 10
      },
      imgDict: {
        sideProfile: this.$t("cases.createImage.cmx"),
        frontal: this.$t("cases.createImage.zmx"),
        frontalSmile: this.$t("cases.createImage.wxx"),
        upperArch: this.$t("cases.createImage.sylx"),
        anteriorOverjet: this.$t("cases.createImage.qyfhfgx"),
        lowerArch: this.$t("cases.createImage.xylx"),
        intraOralRight: this.$t("cases.createImage.knzcyx"),
        intraOralFrontal: this.$t("cases.createImage.knzwx"),
        panoramicRadiograph: this.$t("cases.createImage.dcp"),
        lateralCephalogram: this.$t("cases.createImage.dwp"),
        intraOralLeft: this.$t("cases.createImage.knycyx")
      }
    };
  },
  computed: {
    ...mapState({
      isShowNews: state => state.isShowNews
    })
  },
  mounted() {
    let query = this.$route.query;
    if (query.id) {
      this.schemeId = query.id;
      this.isLoading = true;
      schemeDetail(query.id).then(detail => {
        this.setFormData(detail);
        this.isLoading = false;
      });
    }

    getQiniuToken().then(data => {
      this.uploadToken = data;
    });
  },
  methods: {
    UPLOAD_URL() {
      return UPLOAD_URL;
    },
    handleDrop(event, filed, name) {
      const file = event.dataTransfer.files[0];
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        // this.isLoading = true;
        this.beforeDrop(file, filed, name);
      }
    },
    async beforeDrop(file, filed, name) {
      const params = await new Promise((resolve, reject) => {
        const formData = new FormData();
        // 在form实例中添加 file qiniuToekn 固定格式类名
        formData.append("file", file);
        formData.append("token", this.uploadToken);
        formData.append("key", nanoid());
        const fileName = file.name.replace(/\s/g, ""); // 去除空格
        const ts = new Date().getTime();
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://upload.qiniup.com/", true);
        // 携带登录token
        xhr.setRequestHeader("Authorization", localStorage.getItem("token"));

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // 根据组件 api 返回固定格式的 url
            const response = JSON.parse(xhr.responseText);
            // const alt = response.key;
            resolve(response);
          } else if (xhr.readyState === 4 && xhr.status !== 200) {
            reject("上传失败"); // 如果上传失败，返回错误信息
          }
        };
        xhr.send(formData);
      });
      this.carouselList = this.carouselList.filter(v => v?.filed !== filed);
      this.uploadSuccess(params, filed, name);
    },
    changeTabs(val) {
      if (val === this.tabKey) return;
      let route = this.$route;
      if (val === "1") {
        const params = {
          userName: this.planDetail.userName,
          sex: this.planDetail.sex,
          birthday: this.planDetail.birthday,
          id: this.schemeId,
          chiefComplaint: this.planDetail.chiefComplaint,
          toothExtraction: this.toothExtraction,
          growNailFlag: this.growNailFlag,
          keyAdvisoryQuestions: this.planDetail.keyAdvisoryQuestions,
          stlFile: this.stlFile
        };
        if (!params?.userName) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxhz"));
        }
        if (!params.sex) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxxb"));
        }
        if (!params.birthday) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxcs"));
        }
        params.attachmentList = this?.resultObj?.attachmentInfoList?.map(
          (item, i) => {
            return {
              fileKey: item.fileKey,
              fileName: item.fileName,
              key: item.fileUrl,
              mime: "file",
              eTag: item.fileUrl,
              order: i
            };
          }
        );
        for (const key in this.resultObj) {
          if (this.imgList.includes(key)) {
            if (this.resultObj[key]) {
              params[key] = JSON.stringify(this.resultObj[key]);
            }
          }
        }
        this.isLoading = true;
        schemeSave(params).then(async data => {
          this.schemeId = data;
          await this.$router.replace({
            path: route.path,
            query: extend({}, route.query, { id: data })
          });
          this.tabKey = val;
          schemeDetail(data || this.schemeId).then(detail => {
            this.setFormData(detail);
            this.isLoading = false;
          });
          notifyMsg(this, "success", this.$t("cases.createUser.yzc"));
        });
      } else {
        this.tabKey = val;
        if (this.schemeId) {
          this.isLoading = true;
          schemeDetail(this.schemeId).then(detail => {
            this.isLoading = false;
            this.setFormData(detail);
          });
        }
      }
    },
    toStep(val) {
      let route = this.$route;
      if (val === "1") {
        const params = {
          userName: this.planDetail.userName,
          sex: this.planDetail.sex,
          birthday: this.planDetail.birthday,
          id: this.schemeId,
          chiefComplaint: this.planDetail.chiefComplaint,
          toothExtraction: this.toothExtraction,
          growNailFlag: this.growNailFlag,
          keyAdvisoryQuestions: this.planDetail.keyAdvisoryQuestions,
          stlFile: this.stlFile
        };
        if (!params?.userName) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxhz"));
        }
        if (!params.sex) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxxb"));
        }
        if (!params.birthday) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxcs"));
        }
        params.attachmentList = this?.resultObj?.attachmentInfoList?.map(
          (item, i) => {
            return {
              fileKey: item.fileKey,
              fileName: item.fileName,
              key: item.fileUrl,
              mime: "file",
              eTag: item.fileUrl,
              order: i
            };
          }
        );
        this.tabKey = val;
        for (const key in this.resultObj) {
          if (this.imgList.includes(key)) {
            if (this.resultObj[key]) {
              params[key] = JSON.stringify(this.resultObj[key]);
            }
          }
        }
        this.isLoading = true;
        schemeSave(params).then(data => {
          this.schemeId = data;
          this.$router.replace({
            path: route.path,
            query: extend({}, route.query, { id: data })
          });
          schemeDetail(data || this.schemeId).then(detail => {
            this.setFormData(detail);
            this.isLoading = false;
          });
          notifyMsg(this, "success", this.$t("cases.createUser.yzc"));
        });
      } else {
        this.tabKey = val;
        this.isLoading = true;
        schemeDetail(this.schemeId).then(detail => {
          this.setFormData(detail);
          this.isLoading = false;
        });
      }
    },
    initImg(param) {
      const imgObj = {};
      for (const paramKey in param) {
        if (this.imgList.includes(paramKey)) {
          if (param[paramKey]) {
            imgObj[paramKey] = this.initObj(
              param[paramKey],
              paramKey,
              this.imgDict[paramKey]
            );
          }
        }
      }
      let echoFacePic = [
        imgObj["sideProfile"],
        imgObj["frontal"],
        imgObj["frontalSmile"],
        imgObj["upperArch"],
        imgObj["anteriorOverjet"],
        imgObj["lowerArch"],
        imgObj["intraOralRight"],
        imgObj["intraOralFrontal"],
        imgObj["intraOralLeft"]
      ];
      let echoXRayPic = [
        imgObj["panoramicRadiograph"],
        imgObj["lateralCephalogram"]
      ];
      let picList = [...echoFacePic, ...echoXRayPic];
      picList = picList.filter(item => item?.nanoId).filter(Boolean);
      this.carouselList = map(picList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
    },
    setFormData(val) {
      const imgObj = {};
      this.initImg(val.workOrderInfo);
      this.planDetail = val.workOrderInfo;
      this.toothExtraction = val.workOrderInfo.toothExtraction;
      this.growNailFlag = val.workOrderInfo.growNailFlag;
      for (const paramKey in val.workOrderInfo) {
        if (this.imgList.includes(paramKey)) {
          if (val.workOrderInfo[paramKey]) {
            imgObj[paramKey] = this.initObj(
              val["workOrderInfo"][paramKey],
              paramKey,
              this.imgDict[paramKey]
            );
          }
        }
      }
      this.stlFile = val.workOrderInfo.stlFile;
      const fileNameObj = {
        other_file: [],
        iTero: "",
        sharp_upjaw: "",
        sharp_downjaw: ""
      };
      const _list = val?.workOrderAttach?.map(v => {
        if (v.fileKey === "other_file") {
          fileNameObj["other_file"].push({ name: v.fileName });
        } else {
          fileNameObj[v.fileKey] = v.fileName;
        }
        const _type = ["sharp_upjaw", "sharp_downjaw"].includes(v.fileKey)
          ? "stl"
          : "zip";

        return {
          fileKey: v.fileKey,
          fileName: v.fileName,
          fileSource: "DOCTOR",
          fileType: _type,
          fileUrl: v.key,
          mime: "file",
          realFileName: v.fileName.split("_")[v.fileName.split("_")?.length - 1]
        };
      });
      this.fileNameObj = fileNameObj;

      this.resultObj = {
        ...this.resultObj,
        ...imgObj,
        stlFile: val?.workOrderInfo?.stlFile,
        attachmentList: val?.workOrderAttach,
        attachmentInfoList: _list
      };
    },
    initObj(str, filed, name) {
      if (!str) {
        return "";
      }
      let obj = JSON.parse(str);
      !obj.nanoId && (obj.nanoId = obj.ETag);
      !obj.degree && (obj.degree = 0);
      !obj.horizontal && (obj.horizontal = false);
      !obj.vertical && (obj.vertical = false);
      obj.filed = filed;
      obj.name = name;
      return obj;
    },
    removeFiledPic(filed) {
      delete this.resultObj[filed];
      const { diagnosisParams } = this;
      this.diagnosisParams = {
        ...diagnosisParams,
        [filed]: ""
      };
      this.carouselList = this.carouselList.filter(
        item => item.filed !== filed
      );
      this.carouselList.forEach((item, index) => {
        this.carouselList[index].index = index + "";
      });
    },
    saveMsg() {
      let route = this.$route;
      if (this.tabKey === "0") {
        const params = {
          userName: this.planDetail.userName,
          sex: this.planDetail.sex,
          birthday: this.planDetail.birthday,
          id: this.schemeId,
          chiefComplaint: this.planDetail.chiefComplaint,
          toothExtraction: this.toothExtraction,
          growNailFlag: this.growNailFlag,
          keyAdvisoryQuestions: this.planDetail.keyAdvisoryQuestions,
          stlFile: this.stlFile
        };
        if (!params?.userName) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxhz"));
        }
        if (!params.sex) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxxb"));
        }
        if (!params.birthday) {
          return notifyMsg(this, "warning", this.$t("cases.createUser.qtxcs"));
        }
        params.attachmentList = this.resultObj?.attachmentInfoList
          ?.filter(v => this.uploadDict[v.fileKey] === this.stlFile)
          .map((item, i) => {
            return {
              fileKey: item.fileKey,
              fileName: item.fileName,
              key: item.fileUrl,
              mime: "file",
              eTag: item.fileUrl,
              order: i
            };
          });
        for (const key in this.resultObj) {
          if (this.imgList.includes(key)) {
            if (this.resultObj[key]) {
              params[key] = JSON.stringify(this.resultObj[key]);
            }
          }
        }
        this.isLoading = true;
        schemeSave(params)
          .then(data => {
            this.schemeId = data;
            this.$router.replace({
              path: route.path,
              query: extend({}, route.query, { id: data })
            });
            schemeDetail(data || this.schemeId).then(detail => {
              this.setFormData(detail);
              this.isLoading = false;
            });
            notifyMsg(this, "success", this.$t("cases.createUser.yzc"));
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.dialogTipVisible = true;
      }
    },
    submit() {
      this.isLoading = true;
      schemeSubmit({ id: this.schemeId })
        .then(() => {
          notifyMsg(this, "success", "提交成功");
          this.$router.push("/consultation");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    changeExtract(val) {
      this.toothExtraction = val;
    },
    changePlantNail(val) {
      this.growNailFlag = val;
    },
    jumpQuestion(val) {
      this.questionName = val;
      this.tabKey = "0";
    },
    imgChange(obj) {
      this.$forceUpdate();
      for (const key in this.resultObj) {
        if (obj.filed === key) {
          const current = this.resultObj[key];
          this.$set(current, "degree", obj.degree);
          this.$set(current, "horizontal", obj.horizontal);
          this.$set(current, "vertical", obj.vertical);
        }
      }
    },
    showCarousel(item) {
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
          this.$refs["previewImg"].show(item?.text, item?.index);
      });
    },
    otherListInit() {
      notifyMsg(this, "warning", this.$t("cases.createImage.zdxz"));
    },
    deleteOtherFile(index, id) {
      this.fileNameObj["other_file"].splice(index, 1);
      this.resultObj.attachmentInfoList = filter(
        map(this.resultObj.attachmentInfoList, item => {
          if (item.id === id) {
            return;
          }
          return item;
        })
      );
    },
    async  handleUpload(file,key,type){
      const _this = this;
      _this.isLoading = true;
      const fileName = file.name.replace(/\s/g, ""); // 去除空格
      const ts = new Date().getTime();
      const params=await new Promise((resolve, reject) => {
        const formData = new FormData();
        // 在form实例中添加 file qiniuToekn 固定格式类名
        formData.append("file", file);
        formData.append("token", this.uploadToken);
        formData.append("key", `${ts}_${fileName}`);
        // 发起xhr请求
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://upload.qiniup.com/", true);
        // 携带登录token
        xhr.setRequestHeader("Authorization", localStorage.getItem("token"));
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            _this.isLoading = false;
            const response = JSON.parse(xhr.responseText);
            resolve(response); // 将图片URL传递给富文本编辑器
          } else if (xhr.readyState === 4 && xhr.status !== 200) {
            _this.isLoading = false;
            reject("上传失败"); // 如果上传失败，返回错误信息
          }
        };
        xhr.send(formData);
      });
      const { attachmentInfoList } = _this.diagnosisParams;
      const list =
          key !== "other_file"
              ? attachmentInfoList.filter(item => item.fileKey !== key)
              : attachmentInfoList;
      _this.diagnosisParams.attachmentInfoList = [
        ...list,
        {
          fileKey: key,
          fileName: params?.key,
          realFileName: fileName,
          fileType: type,
          fileUrl: params?.hash,
          fileSource: "DOCTOR"
        }
      ];
      _this.fileNameObj[key] =
          key !== "other_file"
              ? ts + "_" + fileName
              : [..._this.fileNameObj[key], { name: ts + "_" + fileName }];
    },

    beforeFileUpload(file, key, type, tabKey) {
      const { name } = file;
      let typeList = ["zip", "rar"];
      const fileType = name?.split(".").pop();
      if (
          type === "zip" ? typeList.includes(fileType) : name.indexOf(type) > 0
      ) {
        this.isLoading = true;
        this.handleUpload(file,key,type)
      } else {
        notifyMsg(
            this,
            "warning",
            `${this.$t("common.message.qxz")}${
                type === "zip" ? "zip或rar" : type
            }${this.$t("common.message.wjsc")}`
        );
      }
      return false;
    },

    buttonHandlerChange(val) {
      this.stlFile = val.stlFile;
      this.resultObj.stlFile = val.stlFile;
    },

    getPicIndex(filed) {
      return filter(this.carouselList, item => item.filed === filed)[0]?.index;
    },
    uploadSuccess(res, filed, name, file, type) {
      this.$forceUpdate();
      this.uploading = true;
      const { resultObj } = this;
      resultObj[filed] = { ETag: res.key, nanoId: res.key };
      let index = this.carouselList.length;
      this.carouselList.splice(this.idxObj[filed], 0, {
        nanoId: res.key,
        name,
        ETag: res.key,
        href: this.$PicPrefix + res.hash,
        filed
      });
      this.carouselList.map((item, index) => {
        this.carouselList[index].index = index + "";
      });
      this.isLoading = false;
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
    },

    beforeUpload(file) {
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        this.isLoading = true;
        return true;
      }
    },
    uploadError() {
      this.isLoading = false;
      return notifyMsg(this, "error", this.$t("common.message.scsb"));
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 87.5vh;
  padding: 0 240px;
  position: relative;
}

.tab-box {
  background-color: #efeff4;
  z-index: 1000;
  position: fixed;
  top: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
}

.create_tabs {
  width: 13.44rem;
  padding: 0 0.3rem 0 0.3rem;
  height: 0.72rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #fff;

  transition: top 0.3s;

  margin: auto 0;

  .tab_left {
    display: flex;
    align-items: center;
    height: 100%;

    .tab_single {
      //width: 2.5rem;
      border-bottom: 0.05rem solid transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 100%;
      font-size: 18px;
      margin-right: 60px;
      color: $main_theme_color_999;
      text-align: center;

      span {
        display: inline-block;
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.04rem;
        background-size: 100% 100%;
        border-radius: 100%;
      }
    }

    .tab_active {
      color: $main_theme_color_333;
      border-color: $main_theme_color;
      //z-index: 9999;
    }
  }

  .tab_right {
    display: flex;
    align-items: center;

    .next_page {
      width: 102px;
      height: 36px;
      background: #fcc80e;
      border-radius: 4px;
      color: $main_theme_color_333;
      font-size: 14px;
    }

    .save_btn {
      width: 96px;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #bbbbbb;
      margin-left: 10px;
      color: $main_theme_color_333;
      font-size: 14px;
    }
  }
}

.create_user {
  width: 13.44rem;
  background-color: #fff;
  border-radius: 0.06rem;
  padding: 0.16rem 0.3rem 0.6rem;
  margin: auto;
  position: relative;
  top: 75px;

  .user_head {
    padding-bottom: 0.16rem;
    border-bottom: 0.01rem solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;

    .user_head_left {
      display: flex;
      align-items: center;
      font-size: 0.18rem;

      .vertical_line {
        display: inline-block;
        width: 0.04rem;
        height: 0.26rem;
        background-color: $main_theme_color;
        margin-right: 0.1rem;
      }
    }

    .user_head_p {
      display: flex;
      align-items: center;
      color: $main_theme_color_333;

      .user_head_sp {
        font-size: 0.14rem;
        margin-right: 0.1rem;
      }

      .case_number {
        font-size: 0.2rem;
      }
    }
  }

  .form_ul {
    padding-top: 0.3rem;
    margin-bottom: 0.51rem;
    font-size: 0.16rem;

    .f_single {
      display: flex;
      align-items: center;
      margin-bottom: 0.3rem;

      .f_single_left {
        margin-right: 0.2rem;
        width: 2rem;
        text-align: right;
        float: right;
        color: $main_theme_color_333;
        white-space: nowrap;

        span {
          color: $main_theme_color_red;
          font-size: 0.14rem;
        }
      }

      .f_single_right {
        flex: 1;
        border-radius: 0.02rem;
        color: $main_theme_color_333;
        position: relative;

        input {
          width: 2.74rem;
          height: 0.4rem;
          box-sizing: border-box;
          outline: none;
          padding-left: 0.19rem;
          border: 1px solid #bbbbbb;
          border-radius: 4px;
        }

        .select_box {
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;

          select {
            width: 2.74rem;
            height: 0.4rem;
            border: none;
            background-color: #f4f4f4;
            padding: 0.1rem 0.19rem;
            box-sizing: border-box;
          }
        }

        .iup_box {
          width: 5.68rem;

          input {
            width: 100%;
            height: 0.42rem;
          }
        }
      }
    }
  }

  .clinic_box,
  .product_box {
    overflow: hidden;
    position: relative;
    margin-bottom: 0.51rem;
  }

  .clinic_list {
    margin-top: 0.3rem;
    padding-left: 30px;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    z-index: 1;

    .clinic_single {
      display: inline-block;
      width: 2.58rem;
      margin-right: 0.64rem;
      margin-bottom: 0.3rem;
      cursor: pointer;
      position: relative;

      .mask_layer {
        width: 2.58rem;
        height: 176px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        position: absolute;
        left: 0;
        top: 0;
        //z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .clinic_product {
        width: 73%;

        p {
          white-space: normal;
          line-height: 0.2rem;
          margin-top: -0.02rem;
        }
      }

      .veil_box {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        z-index: 9;
      }
    }

    & div:nth-child(4n) {
      margin-right: 0;
    }
  }

  .pre_p,
  .next_p {
    position: absolute;
    top: 0.34rem;
    width: 0.4rem;
    height: 1.64rem;
    cursor: pointer;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: none;
  }

  .pre_p {
    left: 0;
  }

  .next_p {
    right: 0;
  }
}

.case-question {
  .case-title {
    display: flex;
    align-items: flex-end;
    font-size: 18px;
    line-height: 26px;
    color: #333;
    margin: 20px 16px 10px;

    .link {
      display: flex;
      align-items: center;
      color: #fcc80e;
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 3px;
      color: #666666;
    }
  }

  .required-title {
    padding-left: 10px;
    display: flex;
    align-items: flex-end;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ed4027;
      margin-right: 4px;
    }
  }

  .have-space {
    .space-tips {
      font-size: 12px;
      color: #666666;
      margin-bottom: 20px;
    }
  }

  .c-q-content {
    padding-left: 80px;

    .need-tips {
      margin-bottom: 20px;
      color: #999;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
    }

    .model-tips {
      margin-bottom: 20px;
      color: #ed4027;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 20px;
      color: #333;
      margin: 10px 0 20px;
    }

    .has-other-input {
      padding-right: 290px;
    }
  }

  .up_load_def1 {
    background-image: url("/static/images/createCases/side.png");
  }

  .up_load_def2 {
    background-image: url("/static/images/createCases/front.png");
  }

  .up_load_def3 {
    background-image: url("/static/images/createCases/smile.png");
  }

  .up_load_def4 {
    background-image: url("/static/images/createCases/top.png");
  }

  .up_load_def5 {
    background-image: url("/static/images/createCases/qyfgx.png");
    background-repeat: no-repeat;
  }

  .up_load_def6 {
    background-image: url("/static/images/createCases/bottom.png");
  }

  .up_load_def7 {
    background-image: url("/static/images/createCases/right.png");
  }

  .up_load_def8 {
    background-image: url("/static/images/createCases/main.png");
  }

  .up_load_def9 {
    background-image: url("/static/images/createCases/left.png");
  }

  .up_load_def10 {
    background-image: url("/static/images/createCases/select.png");
    background-repeat: no-repeat;
  }

  .up_load_def11 {
    width: 92px;
    height: 52px;
    left: 50%;
    top: 82px;
    transform: translateX(-50%);
    background-image: url("/static/images/createCases/yhx.png");
    background-repeat: no-repeat;
  }

  .up_load_def12 {
    width: 74px;
    height: 67px;
    left: 50%;
    top: 73px;
    transform: translateX(-50%);
    background-image: url("/static/images/createCases/khx.png");
    background-repeat: no-repeat;
  }

  .upload-img-container {
    display: flex;
    flex-wrap: wrap;

    .upload-img-item {
      width: 344px;
      height: 280px;
      margin: 0 46px 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:nth-child(3n + 3) {
        margin-right: 0px;
      }

      .after-upload {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        //border: 1px solid #bbb;
        border-radius: 8px;
        background-color: #f0f0f0;
        position: relative;
        overflow: hidden;

        &:hover .pic_look {
          opacity: 1;
        }

        .remove_pic {
          position: absolute;
          right: 0.1rem;
          top: 0.1rem;
          width: 0.3rem;
          height: 0.3rem;
          z-index: 9;
          cursor: pointer;
          border-radius: 100%;
          background-size: 100% 100%;
          background-image: url("/static/images/createCases/delet_img.png");
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        .pic_look {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.16rem;
          opacity: 0;
          transition: opacity 0.6s;
          z-index: 1;

          img {
            width: 0.24rem;
            height: 0.16rem;
            margin-right: 0.1rem;
          }
        }
      }

      .before-upload {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        border: 1px solid #bbb;
        border-radius: 8px;
        background-color: #f0f0f0;

        .up_box {
          width: 3.44rem;
          height: 240px;
          position: relative;
          background: #f0f0f0;

          .up_box_tip {
            width: 100%;
            position: absolute;
            bottom: 0.26rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: $main_theme_color_333;

            .up___tip_icon {
              display: inline-block;
              width: 0.25rem;
              height: 0.25rem;
              background-size: 100% 100%;
              background-image: url("/static/images/createCases/add_upload_img.png");
              border-radius: 100%;
              margin-right: 0.1rem;
            }
          }
        }
      }

      .img-name {
        font-size: 16px;
        line-height: 22px;
        color: #333;
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .upload-tips {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  .d_require {
    color: $main_theme_color_red !important;
    font-size: 0.14rem;
  }

  .to_active {
    background: #fcc80e !important;
  }

  .s_c__up {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-bottom: 0.16rem;
    font-size: 14px;
    color: $main_theme_color_333;

    .upload-file {
      margin: 0 0.2rem;
    }

    .up_load {
      display: inline-block;
      height: 0.4rem;
      line-height: 0.4rem;
      margin: 0 0.16rem;
      border-radius: 0.03rem;
      outline: none;
      padding: 0 0.2rem;
      position: relative;
    }

    .up_icon {
      display: inline-block;
      width: 0.24rem;
      height: 0.24rem;
      background-image: url("/static/images/createCases/web_doc_upload.png");
      background-size: 100% 100%;
      cursor: pointer;
    }

    .up_status {
      color: #5cb6ff;
    }

    .up_file {
      display: flex;
      align-items: center;
      margin-left: 0.2rem;

      .up_status {
        color: #5cb6ff;
        margin-left: 0.1rem;
      }
    }

    .other_file {
      margin-bottom: 0.1rem;
    }
  }

  .button-filed-item {
    display: flex;
    align-items: center;

    .label {
      margin-bottom: 20px;
      margin-right: 20px;
      white-space: nowrap;
    }
  }

  .up_load_def {
    position: absolute;
    left: 0;
    top: 0.5rem;
    right: 0;
    height: 1rem;
    z-index: 9;
    background-size: 100% 100%;
  }
}

.btn-box {
  width: 60px;
  height: 40px;
  border: 1px solid #dcdfe6;
  color: #606266;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.active-btn-box {
  background-color: #fcc80e;
  color: #fff;
  border: none;
}

.user_icon {
  background-image: url("../../common/imgs/case/case_tabs_user.png");
}

.user_icon_active {
  background-image: url("../../common/imgs/case/case_tabs_user_y.png");
}

.preview_icon {
  background-image: url("../../common/imgs/case/case_tabs_preview.png");
}

.preview_icon_active {
  background-image: url("../../common/imgs/case/case_tabs_preview_y.png");
}

.top068 {
  top: 0.67rem !important;
}
</style>
