<template>
  <div
    v-if="isLoading"
    :style="{ padding: isCaseData && '0px' }"
    class="preview-table"
  >
    <div v-if="hasQuestion && !isCaseData" class="faultiness">
      <div class="title">{{ $t("cases.maintain.wwcts") }}</div>
      <div class="faultiness-content">
        <div
          v-for="(item, index) in questionList"
          :key="index"
          class="question-item"
          @click="jumpQuestion"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div v-else class="prefect">
      <div v-if="!isCaseData" class="title">
        {{ $t("blzx.bltxwz") }}
      </div>
      <UserInfo :detail="planDetail" />
      <h5 class="s_l_h5">
        <span class="yellow_fz"></span>
        {{ $t("blzx.fazxb") }}
      </h5>
      <div class="prefect-content">
        <div class="echo-result">
          <div>
            <div class="result-title">1. {{ $t("blzx.zs") }}</div>
            <div class="other-input text-content">
              {{ planDetail.chiefComplaint }}
            </div>
          </div>
          <div>
            <div class="result-title">2. {{ $t("blzx.by") }}</div>
            <div class="result-value">
              {{
                planDetail.toothExtraction === "true"
                  ? $t("blzx.Y")
                  : $t("blzx.N")
              }}
            </div>
          </div>
          <div>
            <div class="result-title">3. {{ $t("blzx.zkd") }}</div>
            <div class="result-value">
              {{
                planDetail.growNailFlag === "true" ? $t("blzx.Y") : $t("blzx.N")
              }}
            </div>
          </div>
          <div>
            <div class="result-title ">4. {{ $t("blzx.zyzxwt") }}</div>
            <div class="other-input text-content">
              {{ planDetail.keyAdvisoryQuestions }}
            </div>
          </div>
          <div class="result-title">5. {{ $t("blzx.mxpic") }}</div>
          <div class="face-pic-container">
            <div
              v-for="(item, index) in facePic"
              v-if="planDetail[item.id] && planDetail[item.id].nanoId"
              :key="index"
              class="pic-item"
            >
              <div
                class="after-upload"
                @click="
                  showCarousel({
                    filed: item.id,
                    text: item.name,
                    index: getPicIndex(item.id)
                  })
                "
              >
                <img
                  v-if="planDetail[item.id] && planDetail[item.id].nanoId"
                  :src="
                    `${$PicPrefix}${
                      planDetail[item.id].nanoId
                    }?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      planDetail[item.id]['degree']
                    }deg) scaleY(${
                      planDetail[item.id]['vertical'] ? -1 : 1
                    }) scaleX(${planDetail[item.id]['horizontal'] ? -1 : 1})`
                  }"
                  alt=""
                  class="after-upload-img"
                />
                <p class="pic_look">
                  <img alt="" src="/static/images/createCases/check_icon.png" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <div class="img-name">{{ item.name }}</div>
            </div>
          </div>
          <div class="result-title">6. {{ $t("blzx.xpic") }}</div>
          <div class="xray-pic-container">
            <div
              v-for="(item, index) in XRayPic"
              v-if="planDetail[item.id] && planDetail[item.id].nanoId"
              :key="index"
              class="pic-item"
            >
              <div
                class="after-upload"
                @click="
                  showCarousel({
                    filed: item.id,
                    text: item.name,
                    index: getPicIndex(item.id)
                  })
                "
              >
                <img
                  v-if="planDetail[item.id] && planDetail[item.id].nanoId"
                  :src="
                    `${$PicPrefix}${
                      planDetail[item.id].nanoId
                    }?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      planDetail[item.id]['degree']
                    }deg) scaleY(${
                      planDetail[item.id]['vertical'] ? -1 : 1
                    }) scaleX(${planDetail[item.id]['horizontal'] ? -1 : 1})`
                  }"
                  alt=""
                  class="after-upload-img"
                />
                <p class="pic_look">
                  <img alt="" src="/static/images/createCases/check_icon.png" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <div class="img-name">{{ item.name }}</div>
            </div>
          </div>
          <div class="result-title">7. {{ $t("cases.maintain.yhmx") }}</div>

          <PublicTeethModel
            v-if="workOrderAttach.length"
            :details="planDetail"
            :fileList="workOrderAttach"
            :showTitle="true"
            renderFiled="stlFile"
            renderModelType="siliconeImpression"
          />
        </div>
      </div>
    </div>
    <PreviewImg
      ref="previewImg"
      :carouselList="carouselList"
      @imgChange="imgChange"
    />
  </div>
</template>

<script>
import PreviewImg from "components/preview-image/preview-image.vue";
import PublicTeethModel from "components/treatment-table/preview-teeth-model/index";
import { schemeComplete, caseDoneDetail, schemeDetail } from "common/api/cases";
import { mapActions, mapState } from "vuex";
import { filter, map, extend } from "lodash";
import UserInfo from "@/pages/consultation/userInfo.vue";

export default {
  data() {
    return {
      workOrderAttach: [],
      isLoading: true,
      hasQuestion: false,
      questionList: [],
      curePlanInfo: {},
      userInfo: {},
      planDetail: {
        keyAdvisoryQuestions: "",
        growNailFlag: "",
        toothExtraction: "",
        chiefComplaint: "",
        userName: "",
        sex: "",
        birthday: ""
      },
      imgList: [
        "sideProfile",
        "frontal",
        "frontalSmile",
        "upperArch",
        "anteriorOverjet",
        "lowerArch",
        "intraOralRight",
        "intraOralFrontal",
        "panoramicRadiograph",
        "lateralCephalogram",
        "intraOralLeft"
      ],
      imgDict: {
        sideProfile: this.$t("cases.createImage.cmx"),
        frontal: this.$t("cases.createImage.zmx"),
        frontalSmile: this.$t("cases.createImage.wxx"),
        upperArch: this.$t("cases.createImage.sylx"),
        anteriorOverjet: this.$t("cases.createImage.qyfhfgx"),
        lowerArch: this.$t("cases.createImage.xylx"),
        intraOralRight: this.$t("cases.createImage.knzcyx"),
        intraOralFrontal: this.$t("cases.createImage.knzwx"),
        panoramicRadiograph: this.$t("cases.createImage.dcp"),
        lateralCephalogram: this.$t("cases.createImage.dwp"),
        intraOralLeft: this.$t("cases.createImage.knycyx")
      },
      facePic: [
        {
          id: "sideProfile",
          name: this.$t("cases.createImage.cmx"),
          label: "cmx"
        },
        {
          id: "frontal",
          name: this.$t("cases.createImage.zmx"),
          label: "zmx"
        },
        {
          id: "frontalSmile",
          name: this.$t("cases.createImage.wxx"),
          label: "wxx"
        },
        {
          id: "upperArch",
          name: this.$t("cases.createImage.sylx"),
          label: "sylx"
        },
        {
          id: "anteriorOverjet",
          name: this.$t("cases.createImage.qyfhfgx"),
          label: "qyfhfgx"
        },
        {
          id: "lowerArch",
          name: this.$t("cases.createImage.xylx"),
          label: "xylx"
        },
        {
          id: "intraOralRight",
          name: this.$t("cases.createImage.knzcyx"),
          label: "knzcyx"
        },
        {
          id: "intraOralFrontal",
          name: this.$t("cases.createImage.knzwx"),
          label: "knzwx"
        },
        {
          id: "intraOralLeft",
          name: this.$t("cases.createImage.knycyx"),
          label: "knycyx"
        }
      ],
      XRayPic: [
        {
          id: "panoramicRadiograph",
          name: this.$t("cases.createImage.dcp"),
          label: "dcp"
        },
        {
          id: "lateralCephalogram",
          name: this.$t("cases.createImage.dwp"),
          label: "dwp"
        }
      ],
      carouselList: []
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  components: {
    PreviewImg,
    PublicTeethModel,
    UserInfo
  },
  props: {
    id: {
      type: String,
      required: true
    },
    isCaseData: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail" //治疗表详情
    }),
    imgChange(obj) {
      this.$forceUpdate();
      for (const key in this.planDetail) {
        if (obj.filed === key) {
          const current = this.planDetail[key];
          this.$set(current, "degree", obj.degree);
          this.$set(current, "horizontal", obj.horizontal);
          this.$set(current, "vertical", obj.vertical);
        }
      }
    },
    jumpQuestion() {
      this.$emit("jumpQuestion", "0");
    },
    showCarousel(item) {
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
          this.$refs["previewImg"].show(item.text, item.index);
      });
    },
    getPicIndex(filed) {
      return filter(this.carouselList, item => item.filed === filed)[0].index;
    },
    toComplete() {
      schemeComplete({
        id: this.id || this.$route.query.id,
        type: "scheme_consult"
      })
        .then(res => {
          this.isLoading = true;
          const { groupNameList = [] } = res;
          const questionList = groupNameList;
          this.questionList = questionList;
          if (questionList.length === 0) {
            this.getCurePlanDetail();

            this.hasQuestion = false;
          } else {
            this.hasQuestion = true;
          }
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
    initObj(str, filed, name) {
      if (!str) {
        return "";
      }
      let obj = JSON.parse(str);
      !obj.nanoId && (obj.nanoId = obj.ETag);
      !obj.degree && (obj.degree = 0);
      !obj.horizontal && (obj.horizontal = false);
      !obj.vertical && (obj.vertical = false);
      obj.filed = filed;
      obj.name = name;
      return obj;
    },
    getCurePlanDetail() {
      schemeDetail(this.$route.query.id).then(res => {
        const imgObj = this.initImg(res.workOrderInfo);
        this.planDetail = {
          ...res.workOrderInfo,
          ...imgObj,
          renderFiled: res.workOrderInfo.stlFile
        };
        this.workOrderAttach = res.workOrderAttach || [];
        this.isLoading = true;
      });
    },
    initImg(param) {
      const imgObj = {};
      for (const paramKey in param) {
        if (this.imgList.includes(paramKey)) {
          if (param[paramKey]) {
            imgObj[paramKey] = this.initObj(
              param[paramKey],
              paramKey,
              this.imgDict[paramKey]
            );
          }
        }
      }
      let echoFacePic = [
        imgObj["sideProfile"],
        imgObj["frontal"],
        imgObj["frontalSmile"],
        imgObj["upperArch"],
        imgObj["anteriorOverjet"],
        imgObj["lowerArch"],
        imgObj["intraOralRight"],
        imgObj["intraOralFrontal"],
        imgObj["intraOralLeft"]
      ];
      let echoXRayPic = [
        imgObj["panoramicRadiograph"],
        imgObj["lateralCephalogram"]
      ];
      let picList = [...echoFacePic, ...echoXRayPic];
      picList = picList.filter(item => item?.nanoId).filter(Boolean);
      this.carouselList = map(picList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
      return imgObj;
    },
    init() {
      this.planDetail["sideProfile"] = this.initObj(
        this.planDetail["sideProfile"],
        "sideProfile",
        this.$t("cases.createImage.cmx")
      );
      this.planDetail["frontal"] = this.initObj(
        this.planDetail["frontal"],
        "frontal",
        this.$t("cases.createImage.zmx")
      );
      this.planDetail["frontalSmile"] = this.initObj(
        this.planDetail["frontalSmile"],
        "frontalSmile",
        this.$t("cases.createImage.wxx")
      );
      this.planDetail["upperArch"] = this.initObj(
        this.planDetail["upperArch"],
        "upperArch",
        this.$t("cases.createImage.sylx")
      );
      this.planDetail["anteriorOverjet"] = this.initObj(
        this.planDetail["anteriorOverjet"],
        "anteriorOverjet",
        this.$t("cases.createImage.qyfhfgx")
      );
      this.planDetail["lowerArch"] = this.initObj(
        this.planDetail["lowerArch"],
        "lowerArch",
        this.$t("cases.createImage.xylx")
      );
      this.planDetail["intraOralRight"] = this.initObj(
        this.planDetail["intraOralRight"],
        "intraOralRight",
        this.$t("cases.createImage.knzcyx")
      );
      this.planDetail["intraOralFrontal"] = this.initObj(
        this.planDetail["intraOralFrontal"],
        "intraOralFrontal",
        this.$t("cases.createImage.knzwx")
      );
      this.planDetail["intraOralLeft"] = this.initObj(
        this.planDetail["intraOralLeft"],
        "intraOralLeft",
        this.$t("cases.createImage.knycyx")
      );
      this.planDetail["panoramicRadiograph"] = this.initObj(
        this.planDetail["panoramicRadiograph"],
        "panoramicRadiograph",
        this.$t("cases.createImage.dcp")
      );
      this.planDetail["lateralCephalogram"] = this.initObj(
        this.planDetail["lateralCephalogram"],
        "lateralCephalogram",
        this.$t("cases.createImage.dwp")
      );
      let echoFacePic = [
        this.planDetail["sideProfile"],
        this.planDetail["frontal"],
        this.planDetail["frontalSmile"],
        this.planDetail["upperArch"],
        this.planDetail["anteriorOverjet"],
        this.planDetail["lowerArch"],
        this.planDetail["intraOralRight"],
        this.planDetail["intraOralFrontal"],
        this.planDetail["intraOralLeft"]
      ];
      let echoXRayPic = [
        this.planDetail["panoramicRadiograph"],
        this.planDetail["lateralCephalogram"]
      ];
      let picList = [...echoFacePic, ...echoXRayPic];
      picList = picList.filter(item => item.nanoId);
      this.carouselList = map(picList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
    }
  },
  mounted() {
    this.toComplete();
  }
};
</script>

<style lang="scss" scoped>
.preview-table {
  background: #fff;
  padding: 30px 60px;

  .title {
    font-size: 22px;
    line-height: 30px;
    color: #333;
    margin-bottom: 30px;
  }

  .faultiness-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;

    .question-item {
      cursor: pointer;
      font-size: 18px;
      line-height: 26px;
      color: #333;
      margin-right: 20px;
      margin-bottom: 20px;
      text-decoration: underline;
      word-break: break-all;
    }
  }

  .prefect-content {
    padding-left: 0.24rem;

    .user-card {
      height: 180px;
      border-radius: 6px;
      padding: 30px;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;

      .user-avatar {
        margin-right: 20px;

        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }
      }

      .user-info {
        font-size: 14px;
        line-height: 20px;
        color: #333;

        .user-name {
          font-size: 20px;
          line-height: 28px;
        }

        .case-no {
          margin: 4px 0 10px;
        }

        .clinical-situation {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .echo-result {
    .result-title {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      margin-bottom: 10px;
      margin-top: 30px;
    }

    .result-value {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      margin-bottom: 10px;
    }

    .result-subtitle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #666;

      .label {
        width: 180px;
        text-align: left;
        margin-right: 30px;
      }

      .value {
        color: #333;
      }
    }

    .other-input {
      padding: 10px 20px;
      border-radius: 4px;
      background: #f4f4f4;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      margin-bottom: 10px;
      word-break: break-all;
    }
  }

  .face-pic-container,
  .xray-pic-container {
    display: flex;
    flex-wrap: wrap;

    .pic-item {
      width: 344px;
      height: 280px;
      margin: 0 43px 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:nth-child(3n + 3) {
        margin-right: 0px;
      }
    }

    .after-upload {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 240px;
      //border: 1px solid #bbb;
      border-radius: 8px;
      background-color: #f0f0f0;
      position: relative;
      overflow: hidden;

      &:hover .pic_look {
        opacity: 1;
      }

      .pic_look {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.5rem;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.16rem;
        opacity: 0;
        transition: opacity 0.6s;
        z-index: 1;

        img {
          width: 0.24rem;
          height: 0.16rem;
          margin-right: 0.1rem;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .img-name {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      text-align: center;
      margin-top: 10px;
    }
  }

  .s_l_h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    font-size: 16px;
    color: $main_theme_color_333;
  }
}

.text-content {
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-line;
}
</style>
